import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { StateService } from 'src/app/shared/services/state.service';
import { ProductModel } from '../../models/product-model';
import { ProductDetails } from '../models/product-details';
import { ActivatedRoute } from '@angular/router';
import {Router} from '@angular/router';
import { debounceTime, Subject, Subscription, timer } from 'rxjs';
import {User} from "../models/user";
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
  updateProduct!: ProductModel;    
  showAPIs:boolean = false;
  showApprovers:boolean = false;
  businessArea:string = "";
  subscriptions: Subscription[] = [];
  breadCrumbProduct:string = "";
  breadCrumbProductId:string="";
  cmdbId:string = "";
  approverRequired:string = '';
  appRegistrationId:string = "";  
  checkError: boolean = false;
  showAlert = false;
  disableApprover:boolean = false;        
  approversDataSet:any[] = [];
  apisDataSet:any[]=[];
  defaultApisDataSet: any[]=[];
  apisValue:any=[];
  approversValue:any=[];
  informationData:any[]=[];
  showSuccess:boolean = false;
  showFailure:boolean = false;
  successFull:boolean = false;
  failure:boolean = false;
  successMsg:string='';
  failureMsg:string='';
  feedbackActive: boolean = false;
  loaderActive: boolean = false;
  showCancelButton:boolean = false;
  isCustomOptionForApproverRequied:boolean = true;
  EditProductForm : FormGroup = new FormGroup({});  
  AppNameAndNumber : FormControl = new FormControl('');
  Product = new  FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(59)]);
  ProductId = new FormControl('');
  APIs = new FormControl([], [Validators.required, Validators.minLength(1)]);   
  Approvers = new FormControl('');
  apiSubject: Subject<string> = new Subject();  
  userSubject: Subject<string> = new Subject();
  editAccessEnabled:boolean = false;
  pageInfo:string="A Product is a collection of one or more APIs. Approvers can be assigned to keep control over any new Product subscriptions. Once approved, a subscribing application gets access to all APIs inside a Product. \n\n You are about to edit an existing Product in Azure API Management."
  isReqApprovalAlwaysMandate:boolean=false;
  isPendingSubscriptionsAvailable:boolean = false;
  requiredApprovalMessage:string="You can't change this Product property, because there are existing subscriptions in 'pending for approval' state. ";
  requiredApprovalInfoMessage:string ="This Product property can only be changed if all associated subscriptions have been approved or rejected first. Please visit the <a href='/publisher/my-approvals' target='_blank'>My Approvals </a>page to review those subscriptions before changing this Product property.";
  constructor(private hipManagementService: Hip2ManagementService, private stateservice: StateService, 
  private cdr: ChangeDetectorRef, protected route: ActivatedRoute, private router: Router, private errorHandleService: ErrorHandleService, private modal:ModalService){
    let passedData:any={}; // Type is any as it will map input data with different structure from many pages i.e ds table , View Product 
    passedData=this.router.getCurrentNavigation()?.extras.state??{productName:""};
    if(passedData.parentData != undefined){
      this.breadCrumbProduct=passedData.parentData.productName;
      sessionStorage.setItem(this.route.snapshot.paramMap.get('productId') ?? "",this.breadCrumbProduct);
    }else{
      this.breadCrumbProduct=sessionStorage.getItem(this.route.snapshot.paramMap.get('productId') ?? "")??"";
    }
  }

 ngAfterViewInit() {
  this.cdr.detectChanges();
  }

 ngOnInit(): void {     
  this.breadCrumbProductId = this.route.snapshot.paramMap.get('productId') ?? "";

  this.EditProductForm = new FormGroup({
    Product: this.Product,
    ProductId: this.ProductId,
    AppNameAndNumber: this.AppNameAndNumber,
    AppOwner: new FormControl(''),
    Description: new FormControl('', [Validators.required, Validators.minLength(2)]),
    APIs: this.APIs,
    ProdAppName: new FormControl(''),
    Approvers: this.Approvers,
    RadioWrapper : new FormGroup({
      Published: new FormControl('',[Validators.required]),
      RequiredSubsc: new FormControl('',[Validators.required]),
      RequiredApproval: new FormControl('',[Validators.required])
    })        
  });
    
  this.getProductDetailsByProductId(this.breadCrumbProductId);
  this.onAPIchanges();
  this.modal.register("Required_approval_Mandate");
}

setFormValue(objProduct:ProductDetails){
  this.businessArea = objProduct.businessAreaCode;
  this.cmdbId = objProduct.cmdbId;
  this.breadCrumbProduct = objProduct.productName;
  sessionStorage.setItem(this.route.snapshot.paramMap.get('productId') ?? "",this.breadCrumbProduct);
  this.informationData = [
    {'title':'Application Name', 'value':objProduct.applicationName },
    {'title':'Application Number', 'value': objProduct.cmdbId },          
    {'title':'Business Area', 'value': objProduct.businessAreaCode },
    {'title':'Unit Code', 'value': objProduct.unitCode },
    {'title':'Cost Center', 'value': objProduct.costCenter }
  ];             
 
  objProduct.approvers.forEach(
    element => {
      this.hipManagementService.getListOfADUsers_Approvers(element).subscribe({
       next:(response)=>{
        response.forEach((userData: User) => {
          this.approversValue.push({
            "id":userData.email,
            "label":userData.name
          })
        });
        this.EditProductForm.patchValue({
          Approvers: this.approversValue,
        })
       }
      })
      
    }
  )

  objProduct.apis.forEach(
    element => {
      this.apisValue.push({
        "id":element.apimApiName,
        "label":element.name
      })
    }
  )
  this.EditProductForm.patchValue({
    Product : objProduct.productName,
    ProductId: objProduct.apimProductName,
    Description: objProduct.description,
    AppOwner:objProduct.appOwner,
    AppNameAndNumber: objProduct.applicationName+ " / "+ objProduct.cmdbId,
    APIs: this.apisValue,    
    Approvers: this.approversValue,
    RadioWrapper: {
      "Published": objProduct.state.toLowerCase() == "published" ? "Yes" : "No",
      "RequiredSubsc": objProduct.subscriptionRequired ? "Yes" : "No",
      "RequiredApproval": objProduct.approvalRequired ? "Yes" : "No"
    }
  })
  if(objProduct.approvalRequired) {
    this.setApproverMandate();
  }
  else {
    this.removeApproverMandate();
  }

  this.showAPIs=true;
  this.showApprovers = true;
  this.validateSubAttributeApi();
 }

 setProductAppRegValue(appRegId:string){
  this.appRegistrationId = appRegId;
  this.loaderActive = true;
  this.hipManagementService.getAdApplicationsByAppId(appRegId).subscribe({
    next:(response:any)=> {
      this.loaderActive = false;
      let clientAppName = response[0].clientAppName;      
      this.EditProductForm.patchValue({
        ProdAppName: clientAppName,
      })
    },
    error:(error:any)=>{
      this.loaderActive = false;
    }  
  });
 }

 getProductDetailsByProductId(apimProductId: string) {
  this.loaderActive = true;
  this.hipManagementService.getProductDetailsByProductId(apimProductId).subscribe({
    next:(response:ProductDetails)=> {
      this.loaderActive = false;
      if(response.hasAccess){
        this.editAccessEnabled=!(response.hasReadonlyAccess)
        this.setFormValue(response);
        this.loadApis(response.cmdbId);
        this.setProductAppRegValue(response.productAppRegistration);
      }else{
        this.router.navigate(['/unauthorized']);
      }      
    },
    error:(error:any)=>{
      this.loaderActive = false;
      this.errorHandleService.handleError(error);
      }    
  });
}

loadApis(appId: string){  
  this.loaderActive = true; 
  this.hipManagementService.getAllApimApis(appId).subscribe(
      {          
        next: (api:any) => {                        
          this.loaderActive = false;
          if(api.length > 0) {
            this.apisDataBind(api);
          }
          else{              
            this.apisDataSet = [];
            this.defaultApisDataSet=[];
          }            
        },
        error: (err: string) => {   
          this.loaderActive = false;         
          this.apisDataSet = [];
          this.defaultApisDataSet=[];
        }
      });
 }

ngAfterContentInit() : void {    
  this.subscriptions.push(this.userSubject.pipe(debounceTime(1500)).subscribe(value =>{      
    if (value.length > 2) {
      this.getAdUsers(value);          
    }
    else {
      this.approversDataSet = [];
    }      
  }));     

 }

apisDataBind(data:any){
  this.apisDataSet=[];
  this.defaultApisDataSet = [];
  data.forEach((element: { displayName: any; apimApiId: any; }) => {
    this.apisDataSet.push({
      'id':element.apimApiId,
      'label':element.displayName
    })
  })
  this.defaultApisDataSet=this.apisDataSet;        
}

 getAdUsers(searchText: string):any {
  this.hipManagementService.getListOfADUsers_Approvers(searchText).subscribe({  
    next:(response:any)=>{
      if(response.length > 0) {
        this.approversDataBind(response);              
      }
      else {
        this.approversDataSet =[];
      }        
    },
    error:(error:any)=>{
      this.approversDataSet =[];
    }     
  });
}
 
 userSearch(searchTerm: string){             
  this.userSubject.next(searchTerm);            
 }

 apiSearch(searchTerm: string){       
    if(searchTerm.length) {
      this.apisDataSet = [];
      for (let i = 0; i < this.defaultApisDataSet.length; i++){            
        if(this.defaultApisDataSet[i].label?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
          this.apisDataSet.push(this.defaultApisDataSet[i]);                  
        }
      }
    }
    else {
      this.apisDataSet = this.defaultApisDataSet;
    }
 }

  approversDataBind(data:any) {
    this.approversDataSet=[];
    data.forEach((element: User) => {      
      this.approversDataSet.push({                      
        'id': element.email,
        'label': element.name,
        'customLabel': element.name + ' ['+element.email+']'                                
      });  
    });
  }


  enableApproval(event: any){
    if(event.toLowerCase() == 'yes'){      
      this.disableApprover = false;
      this.setApproverMandate();
    }else if(event.toLowerCase() == 'no'){ 
      this.loaderActive=true;
      this.hipManagementService.getSubscriptionsStatistics('1.0',this.breadCrumbProductId).subscribe({
        next: data =>{
          if(data.pendingSubscriptions == 0){
            this.disableApprover = true;
            this.removeApproverMandate();
          }else{
            this.modal.toggleModal("Required_approval_Mandate");
            this.EditProductForm.patchValue({RadioWrapper:{RequiredApproval: 'Yes'}})
          }
          this.loaderActive=false;
        },
        error: err=>{
          if(err.status ==400){
            this.disableApprover = true;
            this.removeApproverMandate();
            this.loaderActive=false;
          }
        }
      })
    }else{
      this.disableApprover = false;    
      this.setApproverMandate();       
    }    
  }

  setApproverMandate(){
    this.approverRequired = 'yes';
    this.Approvers.setValidators([Validators.required]);
    this.Approvers.updateValueAndValidity();
  }
  removeApproverMandate(){    
    this.Approvers.clearValidators();
    this.Approvers.updateValueAndValidity(); 
    this.approverRequired = 'no';
  }

getControl(control: string): FormControl {
  return this.EditProductForm.get(control) as FormControl;
}
onSubmit() { 
  if (this.EditProductForm.valid) {
    this.editProduct();
   } else {
    this.checkError = true;
  }
}

editProduct() {    
  var approversList:any[]=[];
  var apisList:any[]=[];
 this.EditProductForm.value.Approvers.forEach((element: { id: any; }) => {
  approversList.push(element.id);
 });

  this.EditProductForm.value.APIs.forEach((element: { id: any; }) => {
   apisList.push({ "apimApiId":element.id});
  });
  this.updateProduct = {
      cmdbId:this.cmdbId,
      businessAreaCode:this.businessArea,
      productApprovers:approversList,
      description:this.EditProductForm.value.Description,
      apiList:apisList,        
      clientAppRegistrationID:this.appRegistrationId,
      isPublished:this.EditProductForm.value.RadioWrapper.Published.toLowerCase() == "yes" ? true : false,
      requiresManualApproval: this.EditProductForm.value.RadioWrapper.RequiredApproval.toLowerCase() == "yes" ? true : false,
      requireSubscription: this.EditProductForm.value.RadioWrapper.RequiredSubsc.toLowerCase() == "yes" ? true : false,
      apimProductId:this.EditProductForm.value.ProductId,
      name:this.EditProductForm.value.Product
  }
  this.loaderActive = true;
  this.hipManagementService.editProduct(this.updateProduct).subscribe({
    next:(response:any) => {
      this.loaderActive = false;
      this.successMsg= this.Product.value + ' updated successfully';
        this.showSuccess=true;             
        this.feedbackActive =true; 
        this.successFull = true; 
        this.failure = false; 
        timer(5000).subscribe(x => this.showSuccess = false);       
    },
    error:(error:any) => {        
      this.loaderActive = false;
      let errMsg='';                 
      if(error.error.message){
        errMsg=error.error.message;
      }
      else if(error.error){
        errMsg=error.error;
      } 
      this.failureMsg = this.Product.value + ' update failed with exception: ' + errMsg;
      this.showFailure = true;  
      this.feedbackActive =true;
      this.successFull = false;
      this.failure = true;  
      timer(5000).subscribe(x => this.showFailure = false);             
    }
  });  
}
onClick(pageName:string):void{
  this.router.navigate([`${pageName}`]);
}
cancelAllData(){
  this.EditProductForm.reset();
}
preventSubmit(event: any){  
  event.preventDefault();
}
@HostListener('window: click', ['$event'])
onWindowClick(event: any) {
  // console.log(this.product.value);
}

@HostListener('window: keydown', ['$event'])
onWindowKeyDown(event: any){
  if(this.feedbackActive){
    if (event.keyCode == 9) {
        event.preventDefault();
    }
  }
}

ngOnDestroy(): void {
  this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  this.modal.unRegister("Required_approval_Mandate");
}
onAPIchanges(){
  this.APIs.valueChanges.pipe(debounceTime(1000)).subscribe((x)=>{
       this.validateSubAttributeApi();
  })
}
validateSubAttributeApi(){
  let apiList:any[]=[];
  this.APIs.value?.forEach((api:any)=>{
    let apiValue={
      'apimApiId':api.id
    }
    apiList.push(apiValue);
  })
  let reqPayload={
    "apiList": apiList
  }
  let productInfo=this.EditProductForm.value;
  if(this.EditProductForm.value.ProductId !=undefined){
  this.hipManagementService.getValidateSubscriptionAttributesForAPIs(reqPayload).subscribe(
    {
      next:(response) =>{
        if(response==true){
          this.approverRequired = 'yes';
          this.EditProductForm.patchValue({
            RadioWrapper : {
              RequiredApproval: "Yes"
            } 
          });
          this.Approvers.setValidators([Validators.required]);
          this.Approvers.updateValueAndValidity();
          this.isReqApprovalAlwaysMandate=true;
        }else{
          this.isReqApprovalAlwaysMandate=false;
        }
      }
    }
  )
}
}
}
