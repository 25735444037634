<div #bodyWrapper>
  <app-bread-crumb [links]="[
  {
    'href': '/developer/my-subscriptions',
    'title': 'My Subscriptions', 
    'description': 'An overview of your product subscriptions.'
  }
]" [buttonProps]="btnProps"
(breadCrumbClickEvent)="exportSubscriptions($event)"></app-bread-crumb>
  <section class="content-section container"  #contentSection>
    <!-- <app-table></app-table>
    <app-accordion-table></app-accordion-table> -->
    <div class="ds_c-listing-table">
      <div class="ds_c-listing-table-filter">
        <div class="navbar">
          <span style="position: relative">
            <input
             #searchSubscriptionField
              type="text"
              class="text-field-responsive form-control"
              name="searchSubscriptionvalue"
              [(ngModel)]="searchSubscriptionvalue"
              (ngModelChange)="subscriptionSearch($event)"
              placeholder="Search with at least 3 characters"
              (keydown.enter)="searchSubscriptionField.blur()"
            />
            <span class="search-icon-placement" *ngIf="searchSubscriptionvalue.length==0"></span>
            <span *ngIf="searchSubscriptionvalue.length > 0" class="removeBtn-search" (click)="clearSearch()" ></span>
          </span>
          <span [formGroup]="filterForm" class="filter-placement" style="width: 40rem;">
              <app-multi-select-dropdown class="multi-select" [props]="{placeholder: 'Filter on business area'}" [control]="businessFilter" [dataSets]="businessAreaList" [isSearchEnabled]="isSearchBusinessFilterEnabled"></app-multi-select-dropdown>
          </span>
          <span [formGroup]="stateFilterForm" class="filter-placement" style="width: 40rem;">
            <app-multi-select-dropdown class="multi-select" [props]="{placeholder: 'Filter on state'}" [control]="stateFilter" [dataSets]="stateList" [isSearchEnabled]="isSearchBusinessFilterEnabled" [disableUserInteraction]="this.loaderEnabled"></app-multi-select-dropdown>
          </span>      
        </div>
      </div>
        <span class="col-12 pageControls" style="justify-content: flex-end;">
          <span class="ds_c-renderCount">
            <span>{{totalRecordCount === 0? '00':(((this.pageNo-1) * (itemsPerPage) + 1) === 1) ? '01' : ((this.pageNo-1) * (itemsPerPage) + 1)}} - {{((itemsPerPage) * (this.pageNo) > (this.totalRecordCount)) ? this.totalRecordCount : (itemsPerPage) * (this.pageNo)}} of {{this.totalRecordCount}}</span>
            <app-select-drpdwn [Options]="[10, 20, 30]" [perPageRender]="this.itemsPerPage" (perPageValue)="renderPageValue($event)"></app-select-drpdwn>
          </span>
           <pagination-controls class=" ds_c-pagination" (pageChange)="onTablePage($event)"  [maxSize]="5"  (responsive)="true"  previousLabel=""
          nextLabel=""></pagination-controls>
        </span>
      <div style="overflow-x: auto;">
        <app-shimmer-table *ngIf="this.loaderEnabled == true"></app-shimmer-table>
        <app-ds-table *ngIf="this.loaderEnabled == false" [HeadArray]="subscriptionTableHeaderList" [GridArray]="gridArray" [DataKeys]="dataKeys" [itemsPerPage]="itemsPerPage" [pageNo]="pageNo" [sorting]="sortingHeaderList" [tableInfo]="tableInfo" [totalRecords]="totalRecordCount" (cancelClicked)="onCancelSubscriptionModal($event)"  (reactivateClicked)="onReactivateSubscriptionModal($event)" (deleteClicked)="ondeleteSubscriptionModal($event)" [wordWrapFirstCol]=true></app-ds-table>
      </div>

    </div>
    <span class="col-12 footer-pageControls">
      <span class="ds_c-renderCount">
        <span>{{totalRecordCount === 0? '00':(((this.pageNo-1) * (itemsPerPage) + 1) === 1) ? '01' : ((this.pageNo-1) * (itemsPerPage) + 1)}} - {{((itemsPerPage) * (this.pageNo) > (this.totalRecordCount)) ? this.totalRecordCount : (itemsPerPage) * (this.pageNo)}} of {{this.totalRecordCount}}</span>
        <app-select-drpdwn [Options]="[10, 20, 30]" [perPageRender]="this.itemsPerPage" (perPageValue)="renderPageValue($event)"></app-select-drpdwn>
      </span>
      <pagination-controls class=" ds_c-pagination" (pageChange)="onTablePage($event)"  [maxSize]="5"  (responsive)="true"  previousLabel=""
      nextLabel=""></pagination-controls>
    </span>
  </section>
  <app-pop-ups [props]="{
    'content': this.cancelSubscriptionMessage,
    'heading': 'Cancel Subscription',
    'icon': './assets/table/unPublish.svg',
    'isIconZoomRequired':true,
    id:'cancel_subscription',
    'titleInfo':'A cancelled subscription can be reactivated again.'
  }"
  [isCancelButtonRequired]=true
  (cancelConfirmed)="onCancelSubscription($event)"></app-pop-ups>
  <app-pop-ups [props]="{
    'content': this.reactivateSubscriptionMessage,
    'heading': 'Reactivate Subscription',
    'icon': '../../../../assets/table/reactivation.svg',
    'isIconZoomRequired':true,
    id:'reactivate_subscription',
    'titleInfo':'A reactivated subscription can be cancelled again.'
  }"
  [isCancelButtonRequired]=true
  (cancelConfirmed)="onReactivateSubscription($event)"></app-pop-ups>
  <app-pop-ups [props]="{
    'content': this.deleteSubscriptionMessage,
    'heading': 'Delete Subscription',
    'icon': '../../../../assets/table/bin.svg',
    'isIconZoomRequired':true,
    id:'delete_subscription',
    'titleInfo':'A deleted subscription cannot be restored again.'
  }"
  [isCancelButtonRequired]=true
  (cancelConfirmed)="onDeleteSubscription($event)"></app-pop-ups>
  </div>
  <app-alert *ngIf="showSuccess" [success]="true">
    <div class="success_Icon"><img src="./assets/successIcon.svg"/><p>{{successMsg}}</p></div>   
  </app-alert>
  <app-alert *ngIf="showFailure" [failure]="true">
    <div class="unSuccess_Icon"><img src="../../../../assets/Health-monitor/Unhealthy.svg"/><p>{{failureMsg}}</p></div>   
  </app-alert>
  <app-alert *ngIf="showDownloadEmpty" [warning]="true">
    <div class="warning_Icon"><img src="../../../../assets/warning-icon.svg"/><p>{{failureMsg}}</p></div>   
  </app-alert>
