import { AfterContentChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-product-listing-card',
  templateUrl: './product-listing-card.component.html',
  styleUrls: ['./product-listing-card.component.scss', './styleModule.css']
})
export class ProductListingCardComponent implements OnInit, OnChanges, AfterContentChecked{
  ngOnChanges() {
    this.EnableShowMorefeature();  
  }
  @Input() dataset: any = {}
  @Input() collapsible: boolean = true;
  showFull: boolean = false;
  @Input() itemsPerPage : any;
  @Input() pageNo : any;
  @Input() totalRecords: number = 0;
  @Input() productApis:{productId:string,apiList:{"apimApiName":string, "name":string}[]}[] =[];

  isClickableLabelReq:boolean=true;
  @Output() tagClickEvent:EventEmitter<String>=new EventEmitter();
  ngOnInit(): void {
    this.dataset;
    this.collapsible=true;
  }

  ngAfterContentChecked(): void {
    this.EnableShowMorefeature();  
  }

  expand(){
    this.showFull = !this.showFull;
  }

  onViewClick(data:any){
    sessionStorage.setItem(data.apimProductName,data.name)
    sessionStorage.setItem('productId', data.apimProductName);
    sessionStorage.setItem('productDisplayNameValue', data.name);
  }

  EnableShowMorefeature(){
    this.dataset.forEach((product: any) => {
      let generatedId="description"+product.apimProductName;
      let element=document.getElementById(generatedId);
      let offesetHeight=element?.offsetHeight??0;
      let clientHeight=element?.clientHeight??0;
      let scrollHeight=element?.scrollHeight??0;
      let descriptionShowMore:boolean = false;
      if((scrollHeight - Math.max(offesetHeight,clientHeight)) <=2){
        descriptionShowMore = false;      
          }
          else{
            descriptionShowMore = scrollHeight > Math.max(offesetHeight,clientHeight); 
          }
      let tagGeneratedId="tag"+product.apimProductName;
      let tagElement=document.getElementById(tagGeneratedId);
      let tagOffesetHeight=tagElement?.offsetHeight??0;
      let tagClientHeight=tagElement?.clientHeight??0;
      let tagScrollHeight=tagElement?.scrollHeight??0;
      let tagShowMore = tagScrollHeight != Math.max(tagOffesetHeight,tagClientHeight);

      let apiGeneratedId="api"+product.apimProductName;
      let apiElement=document.getElementById(apiGeneratedId);
      let apiOffesetHeight=apiElement?.offsetHeight??0;
      let apiClientHeight=apiElement?.clientHeight??0;
      let apiScrollHeight=apiElement?.scrollHeight??0;
      let isAPIShowMore=apiScrollHeight> Math.max(apiOffesetHeight,apiClientHeight);
      if(tagShowMore==true || descriptionShowMore==true || isAPIShowMore==true){
        product.EnableMoreOption = true;
      }
    });
  } 

  onTagClick(tagValue: any){
      this.tagClickEvent.emit(tagValue);
  }
}