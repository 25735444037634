import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DateFormatPipe } from 'src/app/pipes/date-format-pipe.pipe';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { Subject, Subscription, debounceTime, timer } from 'rxjs';
import {  ProductSubscriptionDetailList } from '../../products/models/product-subscription-detail';
import { CustomCapitalizePipe } from 'src/app/pipes/capitalize-pipe/custom-capitalize-pipe.pipe';
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { DeleteSubscriptionRequest } from '../models/subscription-delete';
import { Environment } from 'src/environments/environment';


@Component({
  selector: 'app-product-subscription',
  templateUrl: './product-subscription.component.html',
  styleUrls: ['./product-subscription.component.scss'],
  providers:[DateFormatPipe,CustomCapitalizePipe]
})
export class ProductSubscriptionComponent implements OnInit, OnDestroy {
  breadCrumbProductName:string = "";
  breadCrumbProductId:string = "";
  loaderEnabled: boolean = true;
  searchSubscriptionValue: string = ''; 
  gridArray: any[]=[]; // type of gridArray is any as we need to pass that to generic table component
  selectedBusinessvalue: string[] = []; // for maintaining state of business Area filters
  selectedStateValue: string[]=[];
  dataKeys : string[]=[];
  pageNo: number = 1;
  itemsPerPage : number = 10;
  filterForm: FormGroup = new FormGroup({});
  stateFilterForm: FormGroup = new FormGroup({});
  businessFilter: FormControl = new FormControl('');
  stateFilter: FormControl = new FormControl('');
  totalRecordCount:number =0;
  isSearchBusinessFilterEnabled:boolean = false;
  tableInfo:string="subscriptions"
  enableColonDateFormat:boolean=false;
 
  tableHeaderList: string [] =[
   "Subscription Name",
   "Subscriber Name",
   "Application Number",
   "Application Name",
   "Business Area",
   "Application Owner",
   "State",
   "Date Requested",
   "Date Activated",
   "Actions"
 ];
  
 businessAreaList =[
   {
 'id': '1',
 'label': 'CT'
 },
 {
 'id': '2',
 'label': 'CO'
 },
 {
 'id': '3',
 'label': 'IT'
 },
 {
 'id': '4',
 'label': 'PT'
 },
 {
 'id': '5',
 'label': 'VT'
 }
 ];

 stateList =[
  {
'id': '1',
'label': 'Active',
'value': 'active'
},
{
'id': '2',
'label': 'Pending Approval',
'value': 'submitted'
},
{
'id': '3',
'label': 'Rejected',
'value': 'rejected'
},
{
  'id': '4',
  'label': 'Cancelled',
  'value': 'cancelled'
}
];
 
 sortingHeaderList=[{head: 'Subscription Name', key: 'subscriptionName'}
,{head: 'Business Area' , key: 'businessAreaCode'},{head:'Subscriber Name', key: 'subscriberName'},{head:'Application Name', key: 'applicationName'}
,{head: 'Application Number', key: 'cmdbId'}, {head: 'Application Owner', key: 'appOwner'}, {head: 'Date Activated', key: 'activatedDate'},{head:'Date Requested' ,key: 'dateRequested'},{head:'State' ,key: 'state'}
];
 searchSubscribeSubscription!: Subscription;
 searchSubscribeValueChanged:Subject<string> = new Subject<string>();
  @ViewChildren('contentSection') contentSections!: ElementRef[];
  @ViewChildren('bodyWrapper') bodyWrapper!: ElementRef[];
 sectionHeight = 0;
  selectedSubscriptionName: string ='';
  selectedSubscriptionDisplayName: string ='';
  cancelSubscriptionMessage: string ='';
  reactivateSubscriptionMessage:string = '';
  showFailure: boolean = false;
  successMsg: string= "";
  showSuccess: boolean=false;
  failureMsg: string = "";
  deleteSubscriptionMessage: string = "";
  azureWorkBookAllowedEnv = ["internaltst", "prd", "internaldev"];
  isWorkBookEnabled: boolean = false;

  constructor(private router:Router,protected route: ActivatedRoute,private hipManagementService:Hip2ManagementService, private dataPipe: DateFormatPipe, private capitalizePipe :CustomCapitalizePipe, private errorHandleService: ErrorHandleService, private modal:ModalService){
   let queryParams = this.route.snapshot.queryParams;
   this.breadCrumbProductName = queryParams['productName']??'';
  }
  ngOnDestroy(): void {
    this.modal.unRegister('cancel_subscription');
    this.modal.unRegister('reactivate_subscription');
    this.modal.unRegister('delete_subscription');
  }
  ngOnInit(): void {
    this.modal.register('cancel_subscription');
    this.modal.register('reactivate_subscription');
    this.modal.register('delete_subscription');
    this.breadCrumbProductId = this.route.snapshot.paramMap.get('productId') ?? "";
    this.isWorkBookEnabled = this.azureWorkBookAllowedEnv.includes(Environment.environment);
    this.loadSubcriptionList();
    this.filterForm = new FormGroup({
      filter: this.businessFilter
    });
    this.stateFilterForm = new FormGroup({
      filter: this.stateFilter
    });
    this.controlDataChange() 
  }

 // listen for window resize events
 @HostListener('window:resize')
 onResize() {
  this.setContentSectionHeight();
 }
 setContentSectionHeight() {
  const windowHeight = window.innerHeight;
 if(this.contentSections!=undefined){
   this.contentSections.forEach(section => {
     const offsetTop = section.nativeElement.getBoundingClientRect().top;      
     const sectionHeight = windowHeight - Math.floor(offsetTop) - (window.innerWidth * 0.0052 * 4.8);
     //0.0052 is the constant value which will be used as dynamic value changing for font-size and 4.8 is the height of footer in rem  
     section.nativeElement.style.minHeight = `${Math.floor(sectionHeight)}px`;
   });
   }
 }
  
  public renderPageValue(value: number) {
    this.itemsPerPage = value;
    this.pageNo = 1;
    this.loadSubcriptionList()
   }
 

  ngAfterContentInit(): void {
    this.searchSubscribeSubscription = this.searchSubscribeValueChanged.pipe(debounceTime(1500)).subscribe(value=>{
     this.searchSubscriptionValue=value;
     if(this.searchSubscriptionValue.length>2 || this.searchSubscriptionValue.length==0){
       this.pageNo=1;
       this.loadSubcriptionList();    
       this.setContentSectionHeight();  
         }   
    })
    this.setContentSectionHeight();
  }
  controlDataChange(){
    this.businessFilter.valueChanges.subscribe((businessFilterObject) => {
      let businessValue = businessFilterObject.map((obj:any)=>{return obj.label});     
     this.selectedBusinessvalue = businessValue;
     this.pageNo=1;
     this.loadSubcriptionList();    
    });   
    this.stateFilter.valueChanges.subscribe((stateFilterObject)=>{
      let stateValue = stateFilterObject.map((obj:any)=>{return obj.value});
      this.selectedStateValue = stateValue;
      this.loadSubcriptionList();
    })
  }
 
  onTablePage(event: any){
    this.pageNo = event;
    this.loadSubcriptionList()
  }
  loadSubcriptionList(){
    let businessAreaCode = '';
    let stateCode = '';
    //get the selected business code
    this.selectedBusinessvalue.forEach(element => {
      businessAreaCode=businessAreaCode.length==0?element:(businessAreaCode+','+element)
    });
    this.selectedStateValue.forEach(element => {
      stateCode=stateCode.length==0?element:(stateCode+','+element)
    });
    let filter = (this.searchSubscriptionValue.trimStart()).trimEnd();
    // we are currently doing client side table sorting
    let sortDirection = 'asc';
    let pageIndex =  this.pageNo;
    let pageSize = this.itemsPerPage;
    let apiVersion = '1.0';
    this.loaderEnabled = true;
    this.hipManagementService.getProductSubscription(this.breadCrumbProductId!.toString(),businessAreaCode, filter, sortDirection,
    pageIndex, pageSize, apiVersion, stateCode).subscribe
      ({
          next: (subscriptionData:ProductSubscriptionDetailList) => {
            //resetting the grid array in order to get latest data
            if(!(subscriptionData.hasAccess)){
                  this.router.navigate(['/unauthorized'])
            }else{
            this.gridArray=[];
            subscriptionData.subscriptionListData.forEach( subscription=> {
              let subscriberName=(subscription.subscriberName).split(" ");
              let formattedSubscriberName = "";
              subscriberName.forEach(name => {
                let nameValue=this.capitalizePipe.transform(name);
                formattedSubscriberName=formattedSubscriberName.length==0?nameValue:(formattedSubscriberName+" "+nameValue);
              });
                let subscriptionRecord = {
                  "uniqueId":subscription.subscriptionName,
                  "subscriptionName":subscription.subscriptionDisplayName,
                  "subscriberName":formattedSubscriberName,
                  "cmdbId": subscription.cmdbId,
                  "applicationName": subscription.applicationName,
                  "businessAreaCode": subscription.businessAreaCode,
                  "appOwner": subscription.appOwner,
                  "state":subscription.state=="submitted"?"Pending Approval":((subscription.state.charAt(0)).toUpperCase())+(subscription.state).slice(1),
                  "dateRequested": this.dataPipe.transform(subscription.dateRequested, this.enableColonDateFormat),
                  "activatedDate": subscription.activatedDate!=undefined?this.dataPipe.transform(subscription.activatedDate, this.enableColonDateFormat):"  - | -",
                  "Action":subscription.state=="active"? 
                    {  
                      "info": "/publisher/manage-subscriptions/view-subscription/"+subscription.subscriptionName+"/"+this.breadCrumbProductId,
                      "isQueryParamsReqForInfoLink":true,
                      "azureDashboardLink": this.isWorkBookEnabled ? `${Environment.azureSubscriptionWorkbookUri}${subscription.subscriptionDisplayName}` + "%22%7D" : false,
                      "queryparamsforInfoLink": {'subscriptionDisplayName' : subscription.subscriptionDisplayName, 'productDisplayName':this.breadCrumbProductName},
                      "cancel":"cancel"
                  }: subscription.state=="cancelled"? {  
                    "info": "/publisher/manage-subscriptions/view-subscription/"+subscription.subscriptionName+"/"+this.breadCrumbProductId,
                    "isQueryParamsReqForInfoLink":true,
                    "azureDashboardLink": this.isWorkBookEnabled ? `${Environment.azureSubscriptionWorkbookUri}${subscription.subscriptionDisplayName}` + "%22%7D" : false,
                    "queryparamsforInfoLink": {'subscriptionDisplayName' : subscription.subscriptionDisplayName, 'productDisplayName':this.breadCrumbProductName},
                    "reactivate": "reactivate",
                    "delete": "delete"
                }:subscription.state=="rejected"?{
                  "info": "/publisher/manage-subscriptions/view-subscription/"+subscription.subscriptionName+"/"+this.breadCrumbProductId,
                  "isQueryParamsReqForInfoLink":true,
                  "azureDashboardLink": this.isWorkBookEnabled ? `${Environment.azureSubscriptionWorkbookUri}${subscription.subscriptionDisplayName}` + "%22%7D" : false,
                  "queryparamsforInfoLink": {'subscriptionDisplayName' : subscription.subscriptionDisplayName, 'productDisplayName':this.breadCrumbProductName},
                  "delete":"enable delete"
                }:{  
                  "info": "/publisher/manage-subscriptions/view-subscription/"+subscription.subscriptionName+"/"+this.breadCrumbProductId,
                  "isQueryParamsReqForInfoLink":true,
                  "azureDashboardLink": this.isWorkBookEnabled ? `${Environment.azureSubscriptionWorkbookUri}${subscription.subscriptionDisplayName}` + "%22%7D" : false,
                  "queryparamsforInfoLink": {'subscriptionDisplayName' : subscription.subscriptionDisplayName, 'productDisplayName':this.breadCrumbProductName}
              }
                }
                this.gridArray.push(subscriptionRecord);
            }); 
           
            if(this.gridArray.length>0){
              this.dataKeys = Object.keys(this.gridArray[0]);              
            }
            this.totalRecordCount = subscriptionData.totalCount;
            this.resetScroll();
            this.loaderEnabled = false;
            this.setContentSectionHeight();
          }},
          error: (error) => {
      this.loaderEnabled = false;
      this.resetScroll();
      this.setContentSectionHeight();
      this.gridArray=[];
      this.errorHandleService.handleError(error)
          }
          
        });
  }
 
  subscriptionSearch(searchValue: string){
   this.searchSubscribeValueChanged.next(searchValue)
  }
  resetScroll(){    
    const BodyElement: HTMLElement | null = document.getElementById('bodyContent');
    if(BodyElement != null ){
      BodyElement.scrollTop = 0;
    }
  }
  clearSearch(){
    if(this.searchSubscriptionValue.length>2){
      this.searchSubscribeValueChanged.next("");
    }
    this.searchSubscriptionValue="";
  }
  onCancelSubscriptionModal(data:any){
    this.selectedSubscriptionName=data?.uniqueId??'';
    this.selectedSubscriptionDisplayName=data?.subscriptionName;
    this.cancelSubscriptionMessage=`You are about to cancel the following subscription:<br><em>`+this.selectedSubscriptionDisplayName+`</em><br>Do you want to proceed?&nbsp`
    this.modal.toggleModal('cancel_subscription')
  }
  onReactivateSubscriptionModal(data:any){
    this.selectedSubscriptionName=data?.uniqueId??'';
    this.selectedSubscriptionDisplayName=data?.subscriptionName;
    this.reactivateSubscriptionMessage=`You are about to reactivate the following subscription:<br><em>`+this.selectedSubscriptionDisplayName+`</em><br>Do you want to proceed?&nbsp`
    this.modal.toggleModal('reactivate_subscription');
  }
  ondeleteSubscriptionModal(data:any){
    this.selectedSubscriptionName=data?.uniqueId??'';
    this.selectedSubscriptionDisplayName=data?.subscriptionName;
    this.deleteSubscriptionMessage=`You are about to delete the following subscription:<br><em>`+this.selectedSubscriptionDisplayName+`</em><br>Do you want to proceed?&nbsp`
    this.modal.toggleModal('delete_subscription');
  }
  onCancelSubscription(event:any){
    if(event==true){
      this.hipManagementService.putPublisherCancelSubscription(this.selectedSubscriptionName, this.breadCrumbProductId).subscribe(
     {
      next: (response)=>{
        this.showFailure=false;
        this.successMsg=this.selectedSubscriptionDisplayName+' subscription cancelled successfully';
        this.showSuccess=true;
        this.loadSubcriptionList();
        timer(5000).subscribe(()=> this.showSuccess = false);
      },
      error: (err)=>{
        console.log(err);
        this.loaderEnabled=false;
        this.showSuccess=false;
        this.failureMsg = this.selectedSubscriptionDisplayName+ ' subscription cancel failed with exception: '+err;
        this.showFailure=true;
        timer(5000).subscribe(x => this.showFailure = false);
      }
     }
      )
    }
  }
  onReactivateSubscription(event:any){
    if(event==true){
      this.hipManagementService.putPublisherReactivateSubscription(this.selectedSubscriptionName, this.breadCrumbProductId).subscribe(
        {
          next:(response:any)=> {
            this.showFailure=false;
            this.successMsg=this.selectedSubscriptionDisplayName+' subscription reactivated successfully';
            this.showSuccess=true;
            this.loadSubcriptionList();
            timer(5000).subscribe(x => this.showSuccess = false);
          },
          error:(err:any)=> {
            console.log(err);
            this.loaderEnabled=false;
            this.showSuccess=false;
            this.failureMsg = this.selectedSubscriptionDisplayName+ ' subscription reactivation failed with exception: '+err;
            this.showFailure=true;
            timer(5000).subscribe(x => this.showFailure = false);        
          },
        }
      )
    }
  }
  onDeleteSubscription(event:any){
    if(event == true){
      this.loaderEnabled = true;
      let subscriptionDeletePayLoad: DeleteSubscriptionRequest ={
        productId: this.breadCrumbProductId,
        subscriptionName: this.selectedSubscriptionName
      }
      this.hipManagementService.deleteSubscriptionPublisher(subscriptionDeletePayLoad).subscribe({
        next :(response)=>{
             this.loaderEnabled = false;
             this.showFailure=false;
             this.successMsg=this.selectedSubscriptionDisplayName+' subscription deleted successfully';
             this.showSuccess=true;
             this.loadSubcriptionList();
             timer(5000).subscribe(x => this.showSuccess = false);
        },
        error: (err)=>{
             console.error(err);
             this.loaderEnabled = false;
             this.showSuccess=false;
             this.failureMsg = this.selectedSubscriptionDisplayName+ ' subscription deletion failed with exception: '+err;
             this.showFailure=true;
             timer(5000).subscribe((x)=> {this.showFailure = false;})
        }
      })
    }
  }
 }
 
