import { Component, ElementRef, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription, debounceTime, timer } from 'rxjs';
import { CustomCapitalizePipe } from 'src/app/pipes/capitalize-pipe/custom-capitalize-pipe.pipe';
import { DateFormatPipe } from 'src/app/pipes/date-format-pipe.pipe';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { SubscriptionCancelRequest } from '../models/subscription-cancel';
import { SubscriptionReactivateRequest } from '../models/subscription-reactivate';
import { DeleteSubscriptionRequest } from '../models/subscription-delete';
import { Environment } from 'src/environments/environment';
@Component({
  selector: 'app-developer-product-subscription',
  templateUrl: './developer-product-subscription.component.html',
  styleUrls: ['./developer-product-subscription.component.scss'],
  providers: [DateFormatPipe,CustomCapitalizePipe]
})
export class DeveloperProductSubscriptionComponent implements OnInit, OnDestroy {
  searchProductvalue:any='';
  isSearchStateFilterEnabled:boolean = false;
  stateFilter: FormControl = new FormControl('');
  filterForm: FormGroup = new FormGroup({});
  itemsPerPage = 10;
  pageNo = 1;
  totalRecordCount =1;
  loaderEnabled:boolean = true;
  dataKeys : string[]=[];
  tableInfo:string="subscriptions";
  breadCrumbappId:string='';
  breadCrumbAppName:string='';
  searchProductSubscription!: Subscription;
  searchProductValueChanged:Subject<string> = new Subject<string>();
  @ViewChildren('contentSection') contentSections!: ElementRef[];
  @ViewChildren('bodyWrapper') bodyWrapper!: ElementRef[];
  gridArray: any[]=[]; // type of gridArray is any as we need to pass that to generic table component
  stateList =[
    {
  'id': '1',
  'label': 'Active',
  'value': 'active'
  },
  {
  'id': '2',
  'label': 'Pending Approval',
  'value': 'submitted'
  },
  {
  'id': '3',
  'label': 'Rejected',
  'value': 'rejected'
  },
  {
    'id':'4',
    'label': 'Cancelled',
    'value': 'cancelled'
  }
  ];
  productTableHeaderList: string [] =[
    "Product Name",
    "Subscription Name",
    "Subscriber Name",
    "State",
    "Date Requested",
    "Date Activated",
    "Actions"
  ];
  selectedStateValue: string[] = []; // for maintaining state of business Area filters by labels

  sortingHeaderList=[{head:'Product Name',key:'productName'},{head:'Subscription Name',key:'subscriptionName'},
  {head:'Subscriber Name',key:'subscriber'},
  {head:'State',key:'State'},{head:'Date Requested',key:'requestedDate'}
]
cancelSubscriptionMessage:string='';
selectedSubscriptionName: string ='';
selectedSubscriptionDisplayName: string='';
selectedProductId: string='';
reactivateSubscriptionMessage:string='';
  showFailure: boolean = false;
  successMsg: string = '';
  showSuccess: boolean = false;
  failureMsg: string = '';
  deleteSubscriptionMessage:string='';
  azureWorkBookAllowedEnv = ["internaltst", "prd", "internaldev"];
  isWorkBookEnabled: boolean = false;
constructor(protected route: ActivatedRoute,private hipService:Hip2ManagementService, private dateFormatPipe:DateFormatPipe, private capitalizePipe: CustomCapitalizePipe, private modal: ModalService){
  let queryParams = this.route.snapshot.queryParams;
  this.breadCrumbAppName = queryParams['appName']??'';
}
  ngOnDestroy(): void {
    this.modal.unRegister('cancel_subscription');
    this.modal.unRegister('reactivate_subscription');
    this.modal.unRegister('delete_subscription');
  }

ngOnInit(){
  this.isWorkBookEnabled = this.azureWorkBookAllowedEnv.includes(Environment.environment);
  this.breadCrumbappId = this.route.snapshot.paramMap.get('apimProductId') ?? "";
  this.getSubscriptions();
  this.controlDataChange();
  this.modal.register('cancel_subscription');
  this.modal.register('reactivate_subscription');
  this.modal.register('delete_subscription');
}
controlDataChange(){
  //fetching the selected business value and calling the API to get the data based on selected business value
  this.stateFilter.valueChanges.pipe((debounceTime(2000))).subscribe((x) => {
   let businessValue = x.map((obj:any)=>{return obj.value});
   this.selectedStateValue = businessValue;
   this.pageNo=1;
   this.getSubscriptions();
  });    

}
getSubscriptions(){
//get the selected business code
let stateCode = '';
this.gridArray=[];
//get the selected business code
this.selectedStateValue.forEach(element => {
  stateCode=stateCode.length==0?element.toLowerCase():(stateCode+','+element.toLowerCase())
});
let filter = (this.searchProductvalue.trimStart()).trimEnd();
// we are currently doing client side table sorting
let sortOrder = 'asc';
let pageNumber =  this.pageNo;
let pageSize = this.itemsPerPage;
let apiVersion = '1.0';
let cmdbId=this.breadCrumbappId;
this.loaderEnabled=true;
this.hipService.getSubscriptionsByCmdbId(cmdbId, filter, sortOrder, pageNumber,
pageSize, apiVersion,stateCode).subscribe((response)=>{
  console.log('getSubscriptionsByApplicationOwner',response);
  this.totalRecordCount=response.totalCount;
  response.subscriptionDetailsList.forEach((element:any,index:number) => {
    let subscription={
      "uniqueId":element.subscriptionName,
       productName:element.productName,
       subscriptionName:element.subscriptionDisplayName,
       subscriber: this.capitalizePipe.transform(element.subscriberName),
       State:element.status=='submitted'?'Pending Approval':this.capitalizePipe.transform(element.status),
       requestedDate:this.dateFormatPipe.transform(element.createdOn),
       activatedDate:element.activatedDate!=undefined?this.dateFormatPipe.transform(element.activatedDate):"  - | -",
       "Action": element.status=='active'?
       {  
        "info": "/developer/manage-subscriptions/view-subscription/"+element.subscriptionName+'/'+element.apimProductId,
        "cancel":"/developer/manage-subscriptions/"+this.breadCrumbappId+'?appName='+this.breadCrumbAppName,
        "isQueryParamsReqForInfoLink":true,
        "azureDashboardLink": this.isWorkBookEnabled ? `${Environment.azureSubscriptionWorkbookUri}${element.subscriptionDisplayName}` + "%22%7D" : false,
        "productId":element.apimProductId,
        "queryparamsforInfoLink": {'appName' : this.breadCrumbAppName, 'appId': this.breadCrumbappId, 'productName':element.productName, 'subDisplayName':element.subscriptionDisplayName}
       }:element.status=='cancelled'?{  
        "info": "/developer/manage-subscriptions/view-subscription/"+element.subscriptionName+'/'+element.apimProductId,
        "reactivate":"/developer/manage-subscriptions/"+this.breadCrumbappId+'?appName='+this.breadCrumbAppName,
        "isQueryParamsReqForInfoLink":true,
        "azureDashboardLink": this.isWorkBookEnabled ? `${Environment.azureSubscriptionWorkbookUri}${element.subscriptionDisplayName}` + "%22%7D" : false,
        "productId":element.apimProductId,
        "delete": "enable delete",
        "queryparamsforInfoLink": {'appName' : this.breadCrumbAppName, 'appId': this.breadCrumbappId, 'productName':element.productName, 'subDisplayName':element.subscriptionDisplayName}
       }:element.status=='rejected'?{
        "info": "/developer/manage-subscriptions/view-subscription/"+element.subscriptionName+'/'+element.apimProductId,
        "delete":"/developer/manage-subscriptions/"+this.breadCrumbappId+'?appName='+this.breadCrumbAppName,
        "isQueryParamsReqForInfoLink":true,
        "azureDashboardLink": this.isWorkBookEnabled ? `${Environment.azureSubscriptionWorkbookUri}${element.subscriptionDisplayName}` + "%22%7D" : false,
        "productId":element.apimProductId,
        "queryparamsforInfoLink": {'appName' : this.breadCrumbAppName, 'appId': this.breadCrumbappId, 'productName':element.productName, 'subDisplayName':element.subscriptionDisplayName}
       }:{  
         "info": "/developer/manage-subscriptions/view-subscription/"+element.subscriptionName+'/'+element.apimProductId,
         "isQueryParamsReqForInfoLink":true,
         "azureDashboardLink": this.isWorkBookEnabled ? `${Environment.azureSubscriptionWorkbookUri}${element.subscriptionDisplayName}` + "%22%7D" : false,
         "queryparamsforInfoLink": {'appName' : this.breadCrumbAppName, 'appId': this.breadCrumbappId, 'productName':element.productName, 'subDisplayName':element.subscriptionDisplayName}
     }
     };
     this.gridArray.push(subscription);
  });
  if(response.totalCount >0){
    this.dataKeys = Object.keys(this.gridArray[0]);
  }
  this.loaderEnabled=false;
  this.setContentSectionHeight();
})
}
ngAfterContentInit(): void {
  this.searchProductSubscription = this.searchProductValueChanged.pipe(debounceTime(1500)).subscribe(value=>{
   this.searchProductvalue=value;
   if(this.searchProductvalue.length>2 || this.searchProductvalue.length==0){
     this.pageNo=1;
     this.getSubscriptions();    
     this.setContentSectionHeight();  
       }   
  })
  this.setContentSectionHeight();
}
  productSearch(event:any){
    this.searchProductValueChanged.next(event)
  }
  public renderPageValue(value: number) {
    this.itemsPerPage = value;
    this.pageNo = 1;
    this.getSubscriptions();
   }
   onTablePage(event: any){
    this.pageNo= event;
    this.getSubscriptions();
  }
  childListData(event:any){

  }
  setContentSectionHeight() {
    const windowHeight = window.innerHeight;
    let bodyWrapperHeight: any;
    if(this.bodyWrapper!=undefined){
      this.bodyWrapper.forEach(section =>{
        bodyWrapperHeight = windowHeight - section.nativeElement.getBoundingClientRect().top;
        // console.log('bodyWrapperHeight is', bodyWrapperHeight, windowHeight);
      });
      
    }
   if(this.contentSections!=undefined){
    this.contentSections.forEach(section => {
      const offsetTop = section.nativeElement.getBoundingClientRect().top;      
      const sectionHeight = windowHeight - Math.floor(offsetTop) - (window.innerWidth * 0.0052 * 4.8); //(2.5 * 48);
      // console.log('bodyWrapperHeight is', bodyWrapperHeight," section offset is", offsetTop," and section Height is ", sectionHeight," footer height ", window.innerWidth * 0.0052 * 4.8);
      
      section.nativeElement.style.minHeight = `${Math.floor(sectionHeight)}px`;
    });
   }
  }
  clearSearch(){
    if(this.searchProductvalue.length>2){
       this.searchProductvalue="";
       this.searchProductValueChanged.next("");
    }else{
      this.searchProductvalue="";
    }
  }
  onCancelSubscription(event:any){
    if(event==true){
      this.loaderEnabled=true;
      let cancelSubscriptionRequest:SubscriptionCancelRequest ={
        subscriptionName: this.selectedSubscriptionName,
        productId: this.selectedProductId
      }
      this.hipService.cancelSubscription(cancelSubscriptionRequest).subscribe(
        {
          next:(response:any)=> {
            this.loaderEnabled=false;
            this.showFailure=false;
            this.successMsg=this.selectedSubscriptionDisplayName+' subscription cancelled successfully';
            this.showSuccess=true;
            this.getSubscriptions();
            timer(5000).subscribe(x => this.showSuccess = false);
          },
          error:(err:any)=> {
            console.log(err);
            this.loaderEnabled=false;
            this.showSuccess=false;
            this.failureMsg = this.selectedSubscriptionDisplayName+ ' subscription cancel failed with exception: '+err;
            this.showFailure=true;
            timer(5000).subscribe(x => this.showFailure = false);        
          },
        }
      )
     }
  }

  onCancelClick(data:any){
    this.selectedSubscriptionName=data?.uniqueId??'';
    this.selectedSubscriptionDisplayName=data?.subscriptionName??'';
    let productDetail=data?.Action?.productId??'';
    this.selectedProductId=productDetail;
    this.cancelSubscriptionMessage=`You are about to cancel the following subscription:<br><em>`+this.selectedSubscriptionDisplayName+`</em><br>Do you want to proceed?&nbsp`
    this.modal.toggleModal('cancel_subscription')
  }
  onReactivateSubscription(event:any){
    if(event==true){
      this.loaderEnabled=true;
      let reactivateSubscriptionRequest:SubscriptionReactivateRequest ={
        subscriptionName: this.selectedSubscriptionName,
        productId: this.selectedProductId
      }
      this.hipService.reactivateSubscription(reactivateSubscriptionRequest).subscribe(
        {
          next:(response:any)=> {
            this.loaderEnabled=false;
            this.showFailure=false;
            this.successMsg=this.selectedSubscriptionDisplayName+' subscription reactivated successfully';
            this.showSuccess=true;
            this.getSubscriptions();
            timer(5000).subscribe(x => this.showSuccess = false);
          },
          error:(err:any)=> {
            console.log(err);
            this.loaderEnabled=false;
            this.showSuccess=false;
            this.failureMsg = this.selectedSubscriptionDisplayName+ ' subscription reactivation failed with exception: '+err;
            this.showFailure=true;
            timer(5000).subscribe(x => this.showFailure = false);        
          },
        }
      )
     }
  }
  onReactivateClick(data:any){
    this.selectedSubscriptionName=data?.uniqueId??'';
    this.selectedSubscriptionDisplayName=data?.subscriptionName??'';
    let productDetail=data?.Action?.productId??'';
    this.selectedProductId=productDetail;
    this.reactivateSubscriptionMessage=`You are about to reactivate the following subscription:<br><em>`+this.selectedSubscriptionDisplayName+`</em><br>Do you want to proceed?&nbsp`
    this.modal.toggleModal('reactivate_subscription');
  }
  onDeleteSubscription(event:any){
    if(event==true){
      this.loaderEnabled=true;
      let deleteSubscriptionRequest:DeleteSubscriptionRequest ={
        subscriptionName: this.selectedSubscriptionName,
        productId: this.selectedProductId

      }
      this.hipService.deleteSubscription(deleteSubscriptionRequest).subscribe(
        {
          next:(response:any)=> {
            this.loaderEnabled=false;
            this.showFailure=false;
            this.successMsg=this.selectedSubscriptionDisplayName+' subscription deleted successfully';
            this.showSuccess=true;
            this.getSubscriptions();
            timer(5000).subscribe(x => this.showSuccess = false);
          },
          error:(err:any)=> {
            console.log(err);
            this.loaderEnabled=false;
            this.showSuccess=false;
            this.failureMsg = this.selectedSubscriptionDisplayName+ ' subscription deletion failed with exception: '+err;
            this.showFailure=true;
            timer(5000).subscribe(x => this.showFailure = false);        
          },
        }
      )
     }
  }
  onDeleteClick(data:any){
    this.selectedSubscriptionName=data?.uniqueId??'';
    this.selectedSubscriptionDisplayName=data?.subscriptionName??'';
    let productDetail=data?.Action?.productId??'';
    this.selectedProductId=productDetail;
    this.deleteSubscriptionMessage=`You are about to delete the following subscription:<br><em>`+this.selectedSubscriptionDisplayName+`</em><br>Do you want to proceed?&nbsp`
    this.modal.toggleModal('delete_subscription');
  }
}
