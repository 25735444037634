import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { ActivatedRoute } from '@angular/router';
import { Router} from'@angular/router';
import {DateFormatPipe} from "../../../pipes/date-format-pipe.pipe"
import { ApiWithSubscriptionsInfo, ViewPendingSubscriptionApprovalModel, ViewSubscriptionModel } from '../../subscriptions/models/viewSubscription-model';
import { CustomCapitalizePipe } from 'src/app/pipes/capitalize-pipe/custom-capitalize-pipe.pipe';
import { timer } from 'rxjs';
import { SubscriptionAttribute, SubscriptionAttributeListModel } from '../../models/subscription-attribute-model';
import { RejectSubscriptionRequest } from '../../subscriptions/models/subscription-reject-api';
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';


@Component({
  selector: 'app-view-approval-pending-subscription',
  templateUrl: './view-approval-pending-subscription.component.html',
  styleUrls: ['./view-approval-pending-subscription.component.scss'],
  providers:[DateFormatPipe,CustomCapitalizePipe]
})
export class ViewApprovalPendingSubscriptionComponent  implements OnInit{
  dataset: ViewPendingSubscriptionApprovalModel={
  SubscriptionName: '',
  SubscriptionDisplayName:'',
  ProductName: '',
  SubscriberName: '',
  CmdbId:'',
  BusinessAreaCode:'',
  ApplicationName:'',
  AppOwner:'',
  ActivatedDate:'',
  DateRequested:'',
  ProxyClientID:'',
  SubscriptionKey:'',
  State:'',
  UageNotes:'',
  UnitCode:'',
  CostCenter:'',
  Remarks:'',
  apiData:[]
  };
  detailsInfo:any[]=[];
  subscriptionState:boolean=false;
  productInfo:{parentData:{productName:string}}={parentData:{productName:""}}
  loaderActive: boolean = true;
  approveButton: boolean = false;
  rejectButton: boolean = false;
  breadCrumbSubscriptionName:string = "";
  breadCrumbSubscriptionDisplayName:string = "";
  breadCrumbProductId:string="";
  breadCrumbProductName:string="";
  apimProductId: string ="";
  ProductName = new  FormControl('', [Validators.required, Validators.minLength(3)]);
  ProductId = new FormControl('');
  appRegistrationId: string ="";
  appRegistrationName: string ="";
  subscriberFullName:string="";
  showSubscriptionAttribute:boolean=false;
  showMask = true;
  keyValue:string="";
  pageInfo:string = "A subscription is created against a Product, containing one or more APIs. Once subscribed, an application gets access to all APIs inside a Product in a secure way. \n \n You are about to approve a new Product subscription in Azure API Management."
  pendingAPICallCount:number=0;
  successMsg:string = '';
  showSuccess:boolean = false;
  successFull:boolean = false;
  feedbackActive:boolean = false;
  failureMsg:string ="";
  showFailure:boolean = false;
  failure:boolean = false;
  approverList: string[] = [];
  enableColonDateFormat:boolean = true;
  enableEdit:boolean = true;
  isSubmitted:boolean = false;
  checkValidation:boolean = false;
  checkRemarkValidation:boolean = false;
  currentSavedSubscriptionAttributesWithApi:any=[];
  subscriptionAttributeToolTip:string = "Subscription attributes are a way to embed configurable parameters into a subscription. Those are defined on API level during onboarding and can steer the backend execution of individual API calls through that subscription. Subscription attributes are invisible to subscribers and not intended to store secret values."

  //type is any as the child component has type any for component reuse purpose
  rejectSubscriptionButtonProps:any={
    icon: '',
    content: 'Reject',
    href: '#',
    classType: 'tertiary',
    type:'button'
  }
  disableRejectSubscriptionButtonProps:any={
    icon: '',
    content: 'Reject',
    href: '#',
    classType: 'disable',
    type:'button'
  }
approveSubscriptionButtonProps:any={
  icon: '',
  content: 'Approve',
  href: '#',
  classType: 'primary',
  type:'submit',
}
disableApproveSubscriptionButtonProps:any={
  icon: '',
  content: 'Approve',
  href: '#',
  classType: 'disable',
  type:'submit',
}
cancelSubscriptionButtonProps:any={
    icon: '',
    content: 'Cancel',
    href: '#',
    classType: 'tertiary',
    type:'button'
}
disableCancelSubscriptionButtonProps:any={
  icon: '',
  content: 'Cancel',
  href: '#',
  classType: 'disable',
  type:'button'
}
  constructor(private hipManagementService: Hip2ManagementService,protected route: ActivatedRoute, private router:Router, private dataPipe: DateFormatPipe,protected capitalizePipe :CustomCapitalizePipe, private errorHandleService: ErrorHandleService){
    let passedData:any={}; // Type is any as it will map input data with different structure from many pages i.e ds table , View Product 
    passedData=this.router.getCurrentNavigation()?.extras.state??{};
    if(passedData != undefined && passedData.parentData?.productName != undefined){
      this.breadCrumbProductName=passedData.parentData?.productName??"";
    }else{
      this.breadCrumbProductName=sessionStorage.getItem(this.route.snapshot.paramMap.get('apimProductId') ?? "")??"";
    }
    if(passedData != undefined && passedData.parentData?.subscriptionDisplayName != undefined){
      this.breadCrumbSubscriptionDisplayName =passedData.parentData.subscriptionDisplayName;      
      sessionStorage.setItem(this.route.snapshot.paramMap.get('subscriptionName') ?? "",this.breadCrumbSubscriptionDisplayName);
    }else{
      this.breadCrumbSubscriptionDisplayName=sessionStorage.getItem(this.route.snapshot.paramMap.get('subscriptionName') ?? "")??"";
    }
    }
  
  ngOnInit(): void {
    this.breadCrumbProductId = this.route.snapshot.paramMap.get('apimProductId') ?? "";
    this.breadCrumbSubscriptionName = this.route.snapshot.paramMap.get('subscriptionName') ?? "";
   
    this.getSubscriptionBySubscriptionName(this.breadCrumbSubscriptionName, this.breadCrumbProductId);
  
  }
  
  getSubscriptionBySubscriptionName(subscriptionName: string,apimProductId: string) {
    this.hipManagementService.getPendingApprovalDetails(subscriptionName,apimProductId).subscribe(r => {
      let subscription = r;
      if(!(subscription.hasAccess)){
        this.router.navigate(['/unauthorized']);
      }else{
      this.breadCrumbProductName=subscription.productName;
      sessionStorage.setItem(this.route.snapshot.paramMap.get('apimProductId') ?? "",this.breadCrumbProductName);      
      this.breadCrumbSubscriptionName=subscription.subscriptionName;
      this.breadCrumbSubscriptionDisplayName=subscription.subscriptionDisplayName;
      sessionStorage.setItem(this.route.snapshot.paramMap.get('subscriptionName') ?? "",this.breadCrumbSubscriptionDisplayName);
     
       if(subscription.state=="active")
       {
       this.rejectButton=true;
       }
       else if(subscription.state=="reject")
       {
       this.approveButton=true;
       }
       else{ this.rejectButton=false,
        this.approveButton=false;
      }
      this.subscriberFullName=subscription.subscriberName;
      let subscriberName=(this.subscriberFullName).split(" ");
      let formattedSubscriberName = "";
      subscriberName.forEach(name => {
        let nameValue=this.capitalizePipe.transform(name);
        formattedSubscriberName=formattedSubscriberName.length==0?nameValue:(formattedSubscriberName+" "+nameValue);
      });
  
      if(subscription.subscriptionAttributeList!=null)
      {
        if(subscription.subscriptionAttributeList.length!=0)
        {
          this.showSubscriptionAttribute=true;
        }
     }
    else{ this.showSubscriptionAttribute=false;}
      this.dataset = {
        SubscriptionName: subscription.subscriptionName,
        SubscriptionDisplayName:subscription.subscriptionDisplayName,
        ProductName: subscription.productName,
        SubscriberName: formattedSubscriberName,
        CmdbId:subscription.cmdbId,
        BusinessAreaCode: subscription.businessAreaCode,
        ApplicationName: subscription.applicationName,
        AppOwner:subscription.appOwner,
        ActivatedDate:this.dataPipe.transform(subscription.activatedDate, this.enableColonDateFormat) ,
        DateRequested: this.dataPipe.transform(subscription.dateRequested, this.enableColonDateFormat),
        ProxyClientID: subscription.proxyClientID,
        SubscriptionKey:subscription.subscriptionKey,
        UageNotes:subscription.usageNotes,
        Remarks:subscription.remarks,
        UnitCode:subscription.unitCode,
        CostCenter:subscription.costCenter,
        State: subscription.state=="submitted"?"Pending Approval":((subscription.state.charAt(0)).toUpperCase())+(subscription.state).slice(1),
        apiData:[],
      }
      this.detailsInfo = [
        {'title':'Application Name', 'value':subscription.applicationName},
        {'title':'Application Number', 'value':subscription.cmdbId},
        {'title':'Application Owner', 'value':subscription.appOwner},    
        {'title':'Business Area', 'value':subscription.businessAreaCode},
        {'title':'Unit Code', 'value':subscription.unitCode},    
        {'title':'Cost Center', 'value':subscription.costCenter},
        {'title':'Date Requested', 'value':this.dataPipe.transform(subscription.dateRequested, this.enableColonDateFormat)},
        {'title':'Date Activated', 'value':subscription.activatedDate!=undefined?this.dataPipe.transform(subscription.activatedDate, this.enableColonDateFormat):"  - | -"}
        
      ];
      this.pendingAPICallCount=this.pendingAPICallCount+1;
      this.hipManagementService.getSubscriptionApisByApimProductIdWithSubscriptionAttributes(apimProductId).subscribe(a => {
        this.pendingAPICallCount=this.pendingAPICallCount-1;
        let productAPIs=[];
        for (let index = 0; index < a.length; index++) {
          let element = a[index];
          let api={
            apimApiName: element.apimApiName,
            name:element.name,
            apiOwner:element.apiOwner,
            apiLink:'/publisher/view-api/'+element.apimApiName,
            subscriptionAttributesData: element.subscriptionAttributesData,
            isExpanded: false
          }
          productAPIs.push(api);
        }
        productAPIs?.forEach((v: any, i: any) => {
          const val = (typeof v === 'object') ? Object.assign({}, v) : v;
          this.currentSavedSubscriptionAttributesWithApi.push(val);
      })

        this.dataset.apiData = productAPIs;
      },error=>{
        console.log("error",error)
        this.pendingAPICallCount=this.pendingAPICallCount-1;
      });
      this.loaderActive = false;  
    }},
    error=>{
      this.errorHandleService.handleError(error);
    });
  }
  
  onViewApiClick(apiId:string, apiName:string){
  sessionStorage.setItem(apiId,apiName);
  }
  
  onSubscriptionAttributeValueChange(displayName:any,id:any,ApimApiId:any,event:any){
  this.dataset.apiData?.forEach(api => {
   api.subscriptionAttributesData?.forEach(item =>{
   if(item.displayName == displayName && item.id==id && item.apimApiId==ApimApiId){
      item.value=event.target.value;
    }
   });
  });
  }

  onRemarksValueChange(event:any){
     this.dataset.Remarks=event.target.value;

   }
  
  onApprove(){
    this.checkValidation = true;
    this.checkRemarkValidation = false;
    let isFormValid:boolean = true;
    this.dataset.apiData?.forEach(api => {
      api.subscriptionAttributesData?.forEach(item => {
        if(item.value.length==0 && item.status=='Active'){
          isFormValid = false;
          api.isExpanded=true;
        }
      });
    });
    if(isFormValid){
      this.approveSubscription()
    }
  }

  onReject(){
    this.checkValidation = false;
    this.checkRemarkValidation = true;
    let isFormValid:boolean = true;
    if(this.dataset.Remarks.length==0){
      isFormValid=false;
    }
    if(isFormValid){
      this.rejectSubscription()
    }
  }

  rejectSubscription(){
    this.loaderActive=true; 
    let subscriptionData:RejectSubscriptionRequest={
      SubscriptionName: this.dataset.SubscriptionName,
      ApimProductId: this.breadCrumbProductId,
      reason: this.dataset.Remarks,
    }
    this.hipManagementService.rejectSubscription(subscriptionData).subscribe({
      next:(response:any) => {
        this.isSubmitted=true;
        this.loaderActive=false;
        this.successMsg= this.dataset.SubscriptionDisplayName+ " got rejected successfully";
        this.showSuccess=true;             
        this.feedbackActive =true; 
        this.successFull = true; 
        this.failure = false; 
        this.enableEdit = false;
        this.dataset.apiData= [];
        this.currentSavedSubscriptionAttributesWithApi?.forEach((item: ApiWithSubscriptionsInfo) =>{
          this.dataset.apiData.push(item);
        })
      this.rejectSubscriptionButtonProps=this.disableRejectSubscriptionButtonProps;
      this.approveSubscriptionButtonProps=this.disableApproveSubscriptionButtonProps;
      this.cancelSubscriptionButtonProps=this.disableCancelSubscriptionButtonProps;
        timer(5000).subscribe(x => this.showSuccess = false);       
      },
      error:(error:any) => {    
        this.loaderActive=false; 
        this.isSubmitted=false; 
        let errMsg='';                 
        if(error.error.message){
          errMsg=error.error.message;
        }
        else if(error.error){
          errMsg=error.error;
        }
        this.failureMsg = this.dataset.SubscriptionName + ' update failed with exception: ' + errMsg;
        this.showFailure = true;  
        this.feedbackActive =true;
        this.successFull = false;
        this.failure = true;  
        timer(5000).subscribe(x => this.showFailure = false);       
      }
    });
  }
  
  approveSubscription(){
    this.loaderActive=true;
    let subscriptionAttributelist:SubscriptionAttribute[] =[];
    this.dataset.apiData?.forEach(api => {
      api.subscriptionAttributesData?.forEach(element => {
        let data ={
          description: element.description,
          displayName: element.displayName,
          id: element.id,
          status: element.status,
          isVisible: element.isVisible,
          value: element.value,
          ApimApiId: element.apimApiId
        }
        subscriptionAttributelist.push(data);
      });
     });
    let subscriptionAttributeData:SubscriptionAttributeListModel={
      subscriptionName: this.dataset.SubscriptionName,
      ApimProductId: this.breadCrumbProductId,
      remarks: this.dataset.Remarks,
      subscriptionAttributes: subscriptionAttributelist
    }
    this.hipManagementService.approveSubscription(subscriptionAttributeData).subscribe({
      next:(response:any) => {
        this.isSubmitted=true;
        this.loaderActive=false;
        this.successMsg= this.dataset.SubscriptionDisplayName+ " got approved successfully";
        this.showSuccess=true;             
        this.feedbackActive =true; 
        this.successFull = true; 
        this.failure = false; 
        this.enableEdit = false;
      this.rejectSubscriptionButtonProps=this.disableRejectSubscriptionButtonProps;
      this.approveSubscriptionButtonProps=this.disableApproveSubscriptionButtonProps;
      this.cancelSubscriptionButtonProps=this.disableCancelSubscriptionButtonProps;
        timer(5000).subscribe(x => this.showSuccess = false);       
      },
      error:(error:any) => {    
        this.loaderActive=false; 
        this.isSubmitted=false; 
        let errMsg='';                 
        if(error.error.message){
          errMsg=error.error.message;
        }
        else if(error.error){
          errMsg=error.error;
        }
        this.failureMsg = this.dataset.SubscriptionName + ' update failed with exception: ' + errMsg;
        this.showFailure = true;  
        this.feedbackActive =true;
        this.successFull = false;
        this.failure = true;  
        timer(5000).subscribe(x => this.showFailure = false);       
      }
    }); 
  }
  
  onEditCancel(){
    this.router.navigate(["/publisher/my-approvals"])
  }
  }
  
