
export const Environment = {
  production: true,
  redirectUri: () => `${window.location.origin}/`,
  clientId: '3238616c-9842-406d-abe6-055be67236d9',
  authority: 'https://login.microsoftonline.com/556e6b1f-b49d-4278-8baf-db06eeefc8e9',
  scopes: ["api://948a3288-79ba-4892-9a7b-db512243aea9/access-as-user"],
  managementHIPUri: 'https://ase-p-app-10001236-hip-mgmt-dev.azurewebsites.net',
  esbUri: '',
  azureSubscriptionWorkbookUri: "https://portal.azure.com/#view/AppInsightsExtension/WorkbookViewerBlade/ComponentId/Azure%20Monitor/ConfigurationId/%2Fsubscriptions%2Fea100b2e-e340-4821-b3c5-19a199e931ef%2Fresourcegroups%2Frg-p-app-10001236-hip-prd%2Fproviders%2Fmicrosoft.insights%2Fworkbooks%2F937e1596-8d90-41bc-aa31-4f47d6e37043/WorkbookTemplateName/Subscription%20Dashboard/NotebookParams/%7B%22SubscriptionID%22%3A%22",
  piiLoggingEnabled: true, // log personal and organizational data
  appTitle: 'API Catalog - Development - HIP 2.0',
  environment: 'dev'
};

